import React from "react";
import { IconButton, TableCell, Tooltip } from "@mui/material";
import { Restore } from "@mui/icons-material";
import { grey } from "@mui/material/colors";

type RestoreItemProps = {
  handleRestoreClick: () => void;
};

export const RestoreItem: React.FC<RestoreItemProps> = ({
  handleRestoreClick,
}) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handleRestoreClick();
  };

  return (
    <TableCell align="center">
      <Tooltip
        title={<h2 style={{ color: grey[300] }}>Restore this property</h2>}
        placement="top"
        arrow
      >
        <div>
          <IconButton
            aria-label="more options"
            onClick={handleClick}
            sx={{
              paddingX: "20px",
              borderRadius: "5%",
            }}
          >
            <Restore color={"primary"} sx={{ margin: 0 }} />
          </IconButton>
        </div>
      </Tooltip>
    </TableCell>
  );
};
