import React from "react";
// import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
// project import
// import Loadable from "components/Loadable";
import Layout from "../screens/Layout";
import HomePageProperties from "../screens/HomePageProperties";
import PropertyDetails from "../screens/PropertyDetails";
import InspectionImages from "../screens/InspectionImages/InspectionImages";
import ViewInspectionImage from "../screens/InspectionImages/ViewInspectionImage";
import AllBorrowers from "../screens/ManageUsers/AllBorrowers/AllBorrowers";
import IndividualUser from "../screens/ManageUsers/IndividualUser/IndividualUser";
import CreateNewUser from "../screens/ManageUsers/CreateNewUser/CreateNewUser";
import AddNewProperty from "../screens/ManageUsers/AddNewProperty/AddNewProperty";
import AllLenders from "../screens/ManageUsers/AllLenders/AllLenders";
import UpdateUser from "../screens/ManageUsers/UpdateUser/UpdateUser";
import { Amplify } from "aws-amplify";
import {
  AWS_COGNITO_CLIENT_ID,
  AWS_COGNITO_REGION,
  AWS_COGNITO_USER_POOL_ID,
} from "../config";
import { Authenticator } from "@aws-amplify/ui-react";
import { CustomAmplifyComponents } from "../components/AmplifyCustomAuth/CustomComponents";
import { HomePage } from "../screens/SignOutPages/HomePage";
import { NonAuthorizedUser } from "../screens/NonAuthorizedUser/NonAuthorizedUser";
import { AdminRedirect } from "./AdminRedirect";
import Archived from "../screens/ArchivedProperties";

Amplify.configure({
  Auth: {
    region: AWS_COGNITO_REGION,
    userPoolId: AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: AWS_COGNITO_CLIENT_ID,
  },
});

function AllRoutes() {
  return (
    <Authenticator.Provider>
      <Routes>
        {/* Public Route */}
        {/* <Route path="/" element={<Navigate to="/gpc/p" />} /> */}

        {/* Nested Routes under Authenticator for Protected Routes */}

        {/* non-authorized user */}
        <Route path="nu" element={<NonAuthorizedUser />} />

        <Route
          path="/gpc"
          element={
            <Authenticator
              loginMechanisms={["email"]}
              components={CustomAmplifyComponents}
            >
              {/* Sidebar and layout */}
              <Layout />
            </Authenticator>
          }
        >
          {/* Protected Routes */}
          <Route index element={<AdminRedirect />} />

          <Route index path="p" element={<HomePageProperties />} />
          <Route index path="aap" element={<Archived />} />

          <Route path="p/aap" element={<AddNewProperty />} />
          <Route path="p/:viewType/:propertyId" element={<PropertyDetails />} />
          <Route
            path="p/:viewType/:propertyId/insp"
            element={<InspectionImages />}
          />
          <Route
            path="p/:viewType/:propertyId/insp/img"
            element={<ViewInspectionImage />}
          />

          {/* mb for manage borrowers. Trying to sound fancy :) */}
          <Route path="ml" element={<AllLenders />} />
          <Route path="ml/newuser" element={<CreateNewUser />} />
          <Route path="ml/:userId" element={<IndividualUser />} />
          <Route path="ml/:userId/ap" element={<AddNewProperty />} />
          <Route path="ml/:userId/uu" element={<UpdateUser />} />

          {/* sub routes to view properties under archived */}
          <Route
            path="aap/:viewType/:propertyId"
            element={<PropertyDetails />}
          />
          <Route
            path="aap/:viewType/:propertyId/insp"
            element={<InspectionImages />}
          />
          <Route
            path="aap/:viewType/:propertyId/insp/img"
            element={<ViewInspectionImage />}
          />
          {/* end of  sub routes to view properties under archived */}

          {/* sub routes to view properties under mb */}
          <Route
            path="ml/:userId/p/:viewType/:propertyId"
            element={<PropertyDetails />}
          />
          <Route
            path="ml/:userId/p/:viewType/:propertyId/insp"
            element={<InspectionImages />}
          />
          <Route
            path="ml/:userId/p/:viewType/:propertyId/insp/img"
            element={<ViewInspectionImage />}
          />
          {/* end of  sub routes to view properties under mb */}

          <Route index path="mb" element={<AllBorrowers />} />
          <Route path="mb/:userId" element={<IndividualUser />} />
          <Route path="mb/:userId/ap" element={<AddNewProperty />} />
          <Route path="mb/newuser" element={<CreateNewUser />} />
          <Route path="mb/:userId/uu" element={<UpdateUser />} />

          {/* sub routes to view properties under ml */}
          <Route
            path="mb/:userId/p/:viewType/:propertyId"
            element={<PropertyDetails />}
          />
          <Route
            path="mb/:userId/p/:viewType/:propertyId/insp"
            element={<InspectionImages />}
          />
          <Route
            path="mb/:userId/p/:viewType/:propertyId/insp/img"
            element={<ViewInspectionImage />}
          />
          {/* end of  sub routes to view properties under ml */}
        </Route>

        {/* Redirect to home if no matching route is found */}
        <Route path="*" element={<Navigate to="/gpc" />} />
      </Routes>
    </Authenticator.Provider>
  );
}

export default AllRoutes;
