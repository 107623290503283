import React from "react";
import {
  Box,
  Typography,
  BoxProps,
  List,
  ListItem,
  ListSubheader,
} from "@mui/material";
import { Attachment, InspectionDocument } from "../../../constants/types";
import { cacheOrRequestMediaFromBackend } from "../../../utils/imageUtils";

interface FileListProps extends BoxProps {
  inspections: InspectionDocument[];
  inspId: string | null;
  propertyNames: string;
}

const FileList: React.FC<FileListProps> = ({
  inspections,
  inspId,
  propertyNames,
  ...boxProps
}) => {
  // Filter inspections based on inspId if provided
  const filteredInspections = inspId
    ? inspections.filter((inspection) => inspection._id === inspId)
    : inspections;

  // Construct section data from filtered inspections
  const sectionData = filteredInspections
    .filter(
      (inspection) =>
        inspection.attachments && inspection.attachments.length > 0
    )
    .map((inspection) => {
      return {
        title: inspection.date,
        data: inspection.attachments || [],
      };
    });

  const handleViewFile = async (attachment: Attachment) => {
    // event.stopPropagation();
    if (attachment) {
      const newFileName = `${attachment.name} - File Uploaded for ${propertyNames}`;
      const signedUrl = await cacheOrRequestMediaFromBackend(
        attachment.path,
        newFileName
      );
      if (signedUrl) window.open(signedUrl, "_blank");
    }
  };

  return (
    <Box
      sx={{
        padding: "16px",
        backgroundColor: "background.paper",
        borderRadius: "15px",
        // display: "flex",
        // alignItems: "center",
        // justifyContent: "space-between",
      }}
      {...boxProps}
    >
      <Typography variant="h6">Uploaded Attachments</Typography>
      <List>
        <Box maxHeight="250px" overflow="auto">
          {sectionData.map((section, index) => (
            <React.Fragment key={`section-${index}`}>
              {/* Hide date header if inspId is present */}
              {!inspId && (
                <ListSubheader
                  sx={{
                    borderWidth: "1px",
                    borderLeftWidth: "0px",
                    borderRightWidth: "0px",
                    borderColor: "divider",
                    borderStyle: "solid",
                  }}
                >
                  Inspection on {new Date(section.title).toLocaleDateString()}
                </ListSubheader>
              )}
              {section.data.map((attachment) => (
                <ListItem
                  button
                  key={attachment._id}
                  onClick={() => handleViewFile(attachment)}
                  sx={{
                    backgroundColor: "background.default",
                    py: 1,
                  }}
                >
                  {attachment.name}
                </ListItem>
              ))}
            </React.Fragment>
          ))}
        </Box>
      </List>
    </Box>
  );
};

export default FileList;
