import ApiService from "../api";
import { BASEPATHS } from "../../config";
import {
  Address,
  LatLng,
  PropertyDocument,
  Viewport,
} from "../../constants/types";
import store from "../../ts/store";

export interface PropertyDocument_API_CALL {
  address: Address;
  location: LatLng;
  viewport: Viewport;
  notes?: string;
  loanAllocation: number;
  loanEndDate: Date;
  userIds: string[];
}

export const createProperty = async (property: PropertyDocument_API_CALL) => {
  const apiService = new ApiService();
  try {
    const propertyData = await apiService.post<any>({
      path: "/createProperty",
      dataType: BASEPATHS.property,
      data: {
        ...property,
      },
    });

    if (property.userIds.length > 0) {
      const addUserPromises = property.userIds.map((userId) =>
        addUserToProperty(propertyData.property._id, userId).catch((error) => {
          console.error(`Error adding user ${userId} to property:`, error);
        })
      );

      await Promise.all(addUserPromises);
    }

    return propertyData;
  } catch (error) {
    console.error("Error creating property:", error);
    throw error;
  }
};

export const addUserToProperty = async (propertyId: string, userId: string) => {
  const apiService = new ApiService();
  try {
    const propertyData = await apiService.post<any>({
      path: "/addUserToProperty",
      dataType: BASEPATHS.property,
      data: {
        propertyId: propertyId,
        userId: userId,
      },
    });

    return propertyData;
  } catch (error) {
    console.error("Error creating property:", error);
    throw error;
  }
};

export const addFileToProperty = async (propertyId: string, file: File) => {
  const apiService = new ApiService();
  try {
    const formData = new FormData();
    formData.append("scopeOfWork", file, file.name);

    let basePath = `/addFileToProperty?propertyId=${propertyId}`;

    const propertyData = await apiService.post<any>({
      path: basePath,
      dataType: BASEPATHS.property,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });

    return propertyData;
  } catch (error) {
    console.error("Error uploading scope of work file to property:", error);
    throw error;
  }
};

export const addRoomToProperty = async (
  propertyId: string,
  roomName: string
) => {
  const apiService = new ApiService();
  try {
    const propertyData = await apiService.post<any>({
      path: "/addRoomToProperty",
      dataType: BASEPATHS.property,
      data: {
        property: propertyId,
        name: roomName,
      },
    });
    // Update the property rooms after adding a new room
    getPropertyRooms(propertyId);

    return propertyData;
  } catch (error) {
    console.error("Error creating property:", error);
    throw error;
  }
};

export const updateProperty = async (
  propertyId: String,
  fields: Partial<PropertyDocument>
) => {
  const apiService = new ApiService();
  try {
    const propertyData = await apiService.post<any>({
      path: "/updateProperty",
      dataType: BASEPATHS.property,
      data: {
        _id: propertyId,
        ...fields,
      },
    });

    return propertyData;
  } catch (error) {
    console.error("Error creating property:", error);
    throw error;
  }
};

export const removeBorrowerFromProperty = async (
  userId: string,
  propertyId: string
) => {
  const apiService = new ApiService();
  try {
    const propertyData = await apiService.post<any>({
      path: "/removeBorrowerFromProperty",
      dataType: BASEPATHS.property,
      data: {
        userId: userId,
        propertyId: propertyId,
      },
    });

    return propertyData;
  } catch (error) {
    console.error("Error removing borrower from property:", error);
    throw error;
  }
};

export const getLCProperties = async (isArchived?: boolean) => {
  const apiService = new ApiService();

  try {
    const propertyData = await apiService.get<any>({
      path: "/getLCProperties",
      dataType: BASEPATHS.property,
      data: { isArchived },
    });
    store.dispatch({
      type: "UPDATE_LC_PROPERTIES",
      lcPropertiesData: propertyData.properties,
    });

    return propertyData;
  } catch (error) {
    console.error("Error getting LC properties:", error);
    throw error; // Rethrow the error to be handled by the caller
  }
};

export const archiveProperty = async (
  propertyId: string,
  restore?: boolean
) => {
  const apiService = new ApiService();

  try {
    const propertyData = await apiService.post<any>({
      path: "/archiveProperty",
      dataType: BASEPATHS.property,
      data: { propertyId, restore },
    });

    return propertyData;
  } catch (error) {
    console.error("Error archiving property:", error);
    throw error; // Rethrow the error to be handled by the caller
  }
};

export const getUserProperties = async () => {
  const apiService = new ApiService();

  try {
    const propertyData = await apiService.get<any>({
      path: "/getUserProperties",
      dataType: BASEPATHS.property,
      data: {},
    });

    store.dispatch({
      type: "UPDATE_USER_PROPERTIES",
      userPropertiesData: propertyData.properties,
    });

    return propertyData;
  } catch (error) {
    console.error("Error getting User properties:", error);
    throw error; // Rethrow the error to be handled by the caller
  }
};

export const getPropertyRooms = async (propertyId: string) => {
  const apiService = new ApiService();

  try {
    const propertyRoomsData = await apiService.get<any>({
      path: "/getRoomsForProperty",
      dataType: BASEPATHS.property,
      data: { propertyId },
    });

    store.dispatch({
      type: "UPDATE_PROPERTY_ROOMS",
      propertyRoomsData: propertyRoomsData.rooms,
    });

    return propertyRoomsData;
  } catch (error) {
    console.error("Error getting User properties:", error);
    throw error; // Rethrow the error to be handled by the caller
  }
};
